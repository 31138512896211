import React, {useState} from "react";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getVehicleTypesData} from "../../state/selectors";
import { Button } from "react-bulma-components";
import {readableDate} from "../../utils";
import useEditableTableNumberCell from "../../hooks/useEditableTableNumberCell";
import {updateWorkshopInvoice} from "../Invoicing/updateInvoice";
import useSafeSave from "../../hooks/useSafeSave";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import CreatePartModal from "../PartLookup/CreatePartModal";
import {useVehicleTypeData} from "../../hooks/useVehicleTypeData";

export default function PartHistoryRow({part, endpoint, updateValue, vehicleTypes}) {
  let dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [createPartModalOpen, setCreatePartModalOpen] = useState(false);
  const [lastEdited, setLastEdited] = useState();


  const onSave = e => {
    const url = endpoint + "parts/update/" + part.id;
    const conf = {
      method: "put",
      data: part,
      url
    };
    setEditable(false);

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const checkUpdated = () => {
    return axios
      .get(endpoint + "parts/update/" + part.id)
      .then(
        checkPart =>
          (new Date(checkPart.data.last_saved).getTime() -
            new Date(part.last_saved).getTime()) /
          1000 <
          1
      );
  };


  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newPart = {...part};
    newPart[e.target.name] = e.target.value;
    updateValue(newPart);
  };


  const saveAfterButtonClick = () => {
    const url = endpoint + "parts/update/" + part.id;
    const conf = {
      method: "put",
      data: part,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const buttonClick = prop => {
    part[prop] = !part[prop];
    saveAfterButtonClick();
  };

  return (
    <tr key={part.id}>
      <td>{part.job.vehicle.registration}</td>
      <td>{part.job.vehicle.make}</td>
      <td>{part.job.vehicle.model}</td>
      <td>
        {part.job.vehicle && part.job.vehicle.type
          ? part.job.vehicle.type.name
          : ""}
      </td>
      <td>{part.name}</td>
      <td>{part.part_number}</td>
      <td>
        £
        {parseFloat(part.cost || 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </td>
      <td>{readableDate(part.date_ordered)}</td>
      <td>{part.supplier.display_name}</td>
      {useEditableTableNumberCell({
        propName: "stock_volume",
        value: part.stock_volume,
        onChange: onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableTextAreaCell({
        propName: "parts_lookup_comments",
        value: part.parts_lookup_comments,
        onChange: onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
        }
      )}
      <td className="float-right">
        <Button
          color="warning"
          data-testid={`submit-part-hide${part.id}`}
          onClick={() => setCreatePartModalOpen(true)}
        >
          Create Part Lookup
        </Button>
      </td>
      <td className="float-right">
        <Button
          color="warning"
          data-testid={`submit-part-hide${part.id}`}
          onClick={() => buttonClick('lookup_hidden')}
        >
          {!part.lookup_hidden ? "Hide Part" : "Unhide Part"}
        </Button>
      </td>
      <CreatePartModal
        open={createPartModalOpen}
        setOpen={setCreatePartModalOpen}
        endpoint={endpoint}
        historicPart={part}
        vehicleTypes={vehicleTypes}
      />

    </tr>
  );
}

import React, {useState} from "react";

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import {formatDate} from "../../utils";
import { Button } from "react-bulma-components";
import GenerateFleetModal from "./GenerateFleetModal";

function VehicleInfoSection(props) {
  const [generateFleetModalState, setGenerateFleetModalState] = useState({modalOpen: false});
  const typeFields = props.vehicleTypes;
  const typeObjects = typeFields.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  const handleTypeUpdate = e => {
    const toUpdate = {
      target: {
        name: "type",
        value: typeObjects[e.target.value]
      }
    };
    props.onChange(toUpdate);
  };

  return (
    <Box>
      <h1 className="title">Vehicle Info</h1>
      <Columns>
        <Columns.Column>
          <Form.Control>
            <Form.Label>Vehicle Status</Form.Label>
            <Button
              color={
                props.data.vehicle_status === "ACTIVE" ? "primary" : ""
              }
              onClick={() => props.handleStatus("ACTIVE")}
            >
              Active
            </Button>
            <Button
              color={
                props.data.vehicle_status === "ORDERED" ? "primary" : ""
              }
              onClick={() => props.handleStatus("ORDERED")}
            >
              Ordered
            </Button>
            <Button
              color={
                props.data.vehicle_status === "FORSALE"
                  ? "primary"
                  : ""
              }
              onClick={() => props.handleStatus("FORSALE")}
            >
              For Sale
            </Button>
            <Button
              color={
                props.data.vehicle_status === "SOLD" ? "primary" : ""
              }
              onClick={() => props.handleStatus("SOLD")}
            >
              Sold
            </Button>
            <Button
              color={
                props.data.vehicle_status === "CUSTOMER"
                  ? "primary"
                  : ""
              }
              onClick={() => props.handleStatus("CUSTOMER")}
            >
              External Customer Vehicle
            </Button>
          </Form.Control>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>LCV Sold</Form.Label>
            <input
              className="checkbox"
              type="checkbox"
              name="lcv_sold"
              onChange={props.handleCheckbox}
              checked={props.data.lcv_sold}
            />
          </Form.Field>
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label className="label">Registration</Form.Label>
            <Form.Control>
              <Form.Input
                type="text"
                name="registration"
                onChange={props.onChange}
                value={props.data.registration}
                required
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <label className="label">Make</label>
            <Form.Control>
              <Form.Input
                className="input"
                type="text"
                name="make"
                onChange={props.onChange}
                value={props.data.make}
                required
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Model</Form.Label>
            <Form.Control>
              <Form.Input
                type="text"
                name="model"
                onChange={props.onChange}
                value={props.data.model}
                required
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>VIN Number</Form.Label>
              <Form.Input
                type="text"
                name="vin_number"
                onChange={props.onChange}
                value={props.data.vin_number}
                required
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Type</Form.Label>
              <Form.Select
                onChange={handleTypeUpdate}
                name="type"
                value={props.data.type ? props.data.type.id : typeFields[0].id}
              >
                {typeFields.map(value => (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Date of First Registration</Form.Label>
              <Form.Input
                type="date"
                name="first_registration_date"
                onChange={props.onChange}
                value={
                  props.data.first_registration_date
                  || ""
                }
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <Form.Label>Fleet Number</Form.Label>
          <Form.Field className="has-addons">
            <Form.Control>
              <Form.Input
                type="text"
                name="fleet_number"
                onChange={props.onChange}
                value={props.data.fleet_number}
                required
              />
            </Form.Control>
            <Form.Control>
              <Button
                color={"success"}
                onClick={() => setGenerateFleetModalState({modalOpen: true})}
              >Generate</Button>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Mileage</Form.Label>
            <Form.Control>
              <Form.Input
                type="number"
                name="mileage"
                onChange={props.onChange}
                value={String(props.data.mileage)}
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Last Mileage Update</Form.Label>
              <Form.Input
                type="date"
                name="mileage_last_updated"
                disabled
                readOnly
                value={
                  props.data.mileage_last_updated
                    ? formatDate(new Date(props.data.mileage_last_updated))
                    : ""
                }
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Courtesy Car</Form.Label>
            <input
              className="checkbox"
              type="checkbox"
              name="courtesy_car"
              onChange={props.handleCheckbox}
              checked={props.data.courtesy_car}
            />
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Location</Form.Label>
              <Form.Input
                type="text"
                name="location"
                onChange={props.onChange}
                value={props.data.location}
                required
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Purchase Date</Form.Label>
              <Form.Input
                type="date"
                name="purchase_date"
                onChange={props.onChange}
                value={
                  props.data.purchase_date || ""
                }
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
      <GenerateFleetModal
        {...generateFleetModalState}
        setModalState={setGenerateFleetModalState}
        endpoint={props.endpoint}
        onChange={props.onChange}
        initDate={props.data.delivery_date}
      />
    </Box>
  );
}

export default VehicleInfoSection;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSetTransactionCategoriesDataAction,
} from "../../../../actions/dataActions";
import {
  getDataFresh,
  getTransactionCategoriesData,
  getTransactionCategorysData,
  getTransactionReportingCategoriesData
} from "../../../../state/selectors";
import Spinner from "react-spinner-material";
import { Box } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { useTransactionCategoryData } from "../../../../hooks/useTransactionCategoryData";
import NewTransactionCategory from "./NewTransactionCategory";
import TransactionCategoryRow from "./TransactionCategoryRow";
import {useTransactionReportingCategoryData} from "../../../../hooks/useTransactionReportingCategoryData";

function TransactionCategoryView({endpoint}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);

  const complete = useTransactionCategoryData({ page }, dataFresh);

  const onTransactionCategoryUpdate = (index, newTransactionCategory) => {
    let newState = data;
    newState.results[index] = newTransactionCategory;
    dispatch(createSetTransactionCategoriesDataAction(newState));
  };

  const data = useSelector(getTransactionCategoriesData);
  const transactionReportingCategories = useSelector(getTransactionReportingCategoriesData);

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <div>
      <NewTransactionCategory />
      <Box>
        <Heading size={5}>Transaction Categorys</Heading>
        <Heading size={6}>
          Note: Modifying these values will update the transaction categories of existing
          transactions!
        </Heading>
        <Table>
          <thead>
            <tr>
              <th>Transaction Category</th>
              <th>Transaction Reporting Category</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <TransactionCategoryRow
                key={value.id}
                transactionCategoryData={value}
                index={index}
                endpoint={endpoint}
                onTransactionCategoryUpdate={onTransactionCategoryUpdate}
                reportingCategories={transactionReportingCategories.results}
              />
            ))}
          </tbody>
        </Table>
        <Pagination
          showFirstLast={true}
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        >
          {" "}
        </Pagination>
      </Box>
    </div>
  );
}

export default TransactionCategoryView;

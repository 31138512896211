import React, { useState } from "react";
import { Button } from "react-bulma-components";
import {getPartCustomerCost, getPartCustomerEstimatedCost} from "../../utils";
import { Form } from "react-bulma-components";
import { asyncContainer, Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import { Icon } from "react-bulma-components";
import FAIcon from "../Icon/FAIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";

const AsyncTypeahead = asyncContainer(Typeahead);

function Part({
  part,
  index,
  customer,
  updateParts,
  removePart,
  onSearchPartClick,
  finalized,
  appDefaults
}) {
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [, setTypeahead] = useState();

  const handleChange = e => {
    const newPart = getNewPart(e);
    updateParts(newPart, index);
  };

  const onCheck = prop => {
    let newPart = { ...part, [prop]: !part[prop] };

    if (prop === "cost_override" && newPart[prop] === false) {
      newPart.customer_cost = getPartCustomerCost(newPart, customer, appDefaults);
      newPart.estimated_customer_cost = getPartCustomerEstimatedCost(newPart, customer, appDefaults)
    }

    updateParts(newPart, index);
  };

  const onSearch = e => {
    onSearchPartClick(part, index);
  };

  const getNewPart = e => {
    let newPart = { ...part, [e.target.name]: e.target.value };

    if (e.target.name === "cost") {
      newPart.customer_cost = getPartCustomerCost(newPart, customer, appDefaults);
    } else if (
      e.target.name === "estimated_cost"
    ) {
      newPart.estimated_cost = e.target.value;
      newPart.estimated_customer_cost = getPartCustomerEstimatedCost(newPart, customer, appDefaults)
      newPart.customer_cost = newPart.estimated_customer_cost;
      newPart.cost = newPart.estimated_cost;
    } else if (e.target.name === "estimated_customer_cost") {
      newPart.customer_cost = newPart.estimated_customer_cost;
    }

    return newPart;
  };

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handlePartSupplierSelect = supplier => {
    let newPart = { ...part, supplier: supplier[0] };
    updateParts(newPart, index);
  };

  return (
    <tr>
      <td>
        <Form.Field className="has-addons">
          <Form.Control>
            <Form.Input
              type="text"
              value={part.name}
              name="name"
              onChange={handleChange}
              data-testid="part-name"
            />
          </Form.Control>
          <Form.Control>
            <Button
              data-testid="search-button"
              color="success"
              onClick={onSearch}
            >
              <FAIcon icon={["fas", "search"]} />
            </Button>
          </Form.Control>
        </Form.Field>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            data-testid="est-cost-input"
            className="input"
            type="number"
            value={String(part.estimated_cost)}
            name="estimated_cost"
            onChange={handleChange}
            disabled={finalized}
          />
          <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="number"
            name="estimated_customer_cost"
            onChange={handleChange}
            value={String(part.estimated_customer_cost)}
            disabled={finalized}
          />
          <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <AsyncTypeahead
            id="typeahead"
            labelKey="display_name"
            minLength={2}
            name={"supplier"}
            onSearch={handleSearch}
            onChange={handlePartSupplierSelect}
            placeholder={"Search Supplier"}
            options={searchData}
            ref={typeahead => setTypeahead(typeahead)}
            className="typeahead"
            isLoading={isLoading}
            selected={part.supplier && part.supplier.id ? [part.supplier] : []}
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="text"
            value={part.part_number}
            name="part_number"
            onChange={handleChange}
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="text"
            value={part.part_invoice_number}
            name="part_invoice_number"
            readOnly
            disabled
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            data-testid="cost-input"
            className="input"
            type="number"
            value={String(part.cost)}
            name="cost"
            disabled={true}
          />
          <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="number"
            name="customer_cost"
            value={String(part.customer_cost)}
            checked={part.customer_cost}
            disabled={true}
          />
          <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Checkbox
            type="checkbox"
            name="from_stock"
            checked={part.from_stock}
            disabled
            readOnly
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Checkbox
            type="checkbox"
            name="backorder"
            checked={part.backorder}
            disabled
            readOnly
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Checkbox
            type="checkbox"
            name="to_be_ordered"
            onChange={() => onCheck("to_be_ordered")}
            checked={part.to_be_ordered}
          />
        </Form.Control>
      </td>
      <td>
        <Button
          remove
          onClick={() => removePart(index, part)}
          data-testid="remove-part"
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}
export default Part;

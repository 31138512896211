import React, {useEffect} from "react";
import { Table } from "react-bulma-components";
import { readableDate } from "../../utils";
import logo from "../Nav/img/Logo.jpg";
import { Columns } from "react-bulma-components";

class PrintPickList extends React.Component {

  render() {

    const job = this.props.data;
    const {vehicle} = job;
    const parts = job.work_items.reduce((allParts, workItem) => {
      const parts = workItem.parts.map((part) => {
        return {
          ...part,
          workDescription: workItem.description
        }
      });
      return [...allParts, ...parts];
    }, []);

    return (
      <div>
        <Columns>
          <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd" />
          <h2 className="job-card-print-title" data-testid={"title"}>
            {job.workshop && job.workshop.display_name} -{" "}
            {job.vehicle.registration}{job.vehicle.vin_number ? '/' + job.vehicle.vin_number : ""} - Workshop Date:{" "}
            {job.workshop_date && readableDate(job.workshop_date)}
          </h2>
        </Columns>

        <Columns>
          <Columns.Column>
            <div>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th>Customer2</th>
                    <th>Job ID</th>
                  </tr>
                </thead>
                <tr>
                  <td data-testid={"customer1"}>{job.customer.display_name}</td>
                  <td data-testid={"customer2"}>{job.customer2 ? job.customer2.display_name : ""}</td>
                  <td data-testid={"job-id"}>{job.id}</td>
                </tr>
              </Table>
              <Table bordered size={"fullwidth"}>
                <thead>
                <tr>
                  <td colSpan={1} className="cell-header"><b>MAKE / MODEL:</b></td>
                  <td colSpan={2} data-testid={"vehicle-make/model"}>{vehicle.make} - {vehicle.model}</td>
                </tr>
                <tr className="header-row">
                  <td>Part Description</td>
                  <td>Part Number</td>
                  <td>Supplier</td>
                </tr>
                </thead>
                <tbody>
                {
                  parts.map(({workDescription, ...part}, index) => <tr key={`${index}`}>
                    <td data-testid={"part-picklist-name"}>{part.name ?? ''}</td>
                    <td data-testid={"part-number"}>{part.part_number ?? ''}</td>
                    <td data-testid={"part-supplier"}>{part.supplier?.display_name ?? ''}</td>
                  </tr>)
                }
                </tbody>
              </Table>
            </div>
          </Columns.Column>
        </Columns>
      </div>
    );
  }
}

export default PrintPickList;

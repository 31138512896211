import { Section } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import React, { useState } from "react";
import { Modal } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { useSelector } from "react-redux";
import {getPartLookupData, getPartsData} from "../../state/selectors";
import { usePartsData } from "../../hooks/usePartsData";
import { Columns } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { readableDate } from "../../utils";
import {usePartLookupData} from "../../hooks/usePartLookupData";

export default function PartSearchModal({
  modalOpen,
  currentSearch,
  searchText,
  selectPart,
  partIndex,
  setModalState,
  workIndex
}) {
  const [page, setPage] = useState(1);
  let params = {
    page: page,
    search: currentSearch,
    view: "with_number",
    part_lookup_stock: true
  };

  const complete = usePartLookupData(params, 1, !modalOpen);
  const data = useSelector(getPartLookupData);

  const setModalOpen = val => {
    setModalState({
      modalOpen: val,
      currentSearch,
      searchText,
      partIndex,
      workIndex
    });
  };

  const setSearchText = val => {
    setModalState({
      searchText: val,
      currentSearch,
      modalOpen,
      partIndex,
      workIndex
    });
  };

  const setCurrentSearch = val => {
    setModalState({
      currentSearch: val,
      searchText,
      modalOpen,
      partIndex,
      workIndex
    });
  };

  return (
    <Modal closeOnBlur show={modalOpen} onClose={() => setModalOpen(false)}>
      <Modal.Content className="part-search-content">
        <Section style={{ backgroundColor: "grey" }}>
          <Box>
            <Heading>Parts Selector</Heading>
            <Columns>
              <Columns.Column>
                <Form.Label>Part Search</Form.Label>
                <Form.Field className="has-addons">
                  <Form.Control>
                    <Form.Input
                      onChange={e => {
                        setSearchText(e.target.value);
                      }}
                      onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && setCurrentSearch(searchText)}
                      data-testid="part-search"
                      name="search_text"
                      type="text"
                      placeholder="Search"
                      value={searchText}
                    />
                  </Form.Control>
                  <Form.Control>
                    <Button
                      data-testid="search-button"
                      color="success"
                      onClick={() => {
                        setCurrentSearch(searchText);
                      }}
                    >
                      Search
                    </Button>
                  </Form.Control>
                  <Form.Control>
                    <Button
                      color="warning"
                      onClick={() => {
                        setModalState({
                          modalOpen,
                          partIndex,
                          workIndex,
                          currentSearch: "",
                          searchText: ""
                        });
                      }}
                    >
                      Clear
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Box>
          <Box>
            <Table>
              <thead>
                <tr>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Vehicle Type</th>
                  <th>Part Name</th>
                  <th>Part Number</th>
                  <th>Part Cost</th>
                  <th>Supplier</th>
                  <th>Stock Volume</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {complete &&
                  data &&
                  data.results.map(part => (
                    <tr key={part.id}>
                      <td>{part.make}</td>
                      <td>{part.model}</td>
                      <td>
                        {part.vehicle_type
                          ? part.vehicle_type.name
                          : ""}
                      </td>
                      <td>{part.name}</td>
                      <td>{part.part_number}</td>
                      <td>
                        £
                        {(part.cost || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </td>
                      <td>{part.supplier && part.supplier.display_name}</td>
                      <td>{part.stock_volume}</td>
                      <td>
                        <Button
                          onClick={() => {
                              selectPart(part, partIndex, workIndex);
                              setPage(1);
                            }
                          }
                          color="success"
                        >
                          Select
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              showFirstLast={true}
              onChange={setPage}
              current={page}
              total={Math.ceil(data.count / 25)}
            ></Pagination>
          </Box>
        </Section>
      </Modal.Content>
    </Modal>
  );
}

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Icon } from "react-bulma-components";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";

export default function Supplier({ contact, handleChange, handleCheckbox }) {
  return (
    <Box>
      <h1 className="title">Supplier Details</h1>
      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Supplier Labour Rate</Form.Label>
            <Form.Control>
              <Form.Input
                type="number"
                name="supplier_labour_charge"
                onChange={handleChange}
                value={String(contact.supplier_labour_charge)}
              />
              <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
              </Icon>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Supplier LCV Labour Rate</Form.Label>
            <Form.Control>
              <Form.Input
                type="number"
                name="lcv_rate"
                onChange={handleChange}
                value={String(contact.lcv_rate)}
              />
              <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
              </Icon>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Labour Rate Affected By Customer Markup %</Form.Label>
            <input
              className="checkbox"
              type="checkbox"
              name="labour_rate_affected_by_customer_markup"
              onChange={handleCheckbox}
              checked={contact.labour_rate_affected_by_customer_markup}
            />
          </Form.Field>
        </Columns.Column>
      </Columns>
    </Box>
  );
}

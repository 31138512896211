import React from "react";
import {Button} from "react-bulma-components";
import axios from "axios";
import {createSetDataFreshAction} from "../../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../../state/selectors";



function DepartmentRow(department, endpoint){
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh);

    const onDelete = () => {
        const url = "api/departments/" + department.department.id;
        const conf = { method: "delete", url };
        axios(conf).then(response =>
            dispatch(createSetDataFreshAction(dataFresh + 1))
        );
    };

    return (
        <tr>
            <td>{department.department.id}</td>
            <td>{department.department.name}</td>
            <td>
                <Button
                    color={"danger"}
                    onClick={onDelete}
                >
                    Delete X
                </Button>
            </td>
        </tr>
    );
}
export default DepartmentRow;
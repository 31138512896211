import React, {useState} from "react";
import {Box, Columns} from "react-bulma-components";
import Collapsible from "react-collapsible";
import useInput from "../../hooks/useInput";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import { Form } from "react-bulma-components";
import {useDispatch} from "react-redux";
import {createSetVehicleFieldAction} from "../../actions/dataActions";
import {clear} from "jest-date-mock";

export default function Warranty(props){
    const [searchData, setSearchData] = useState([]);
    const [typeahead, setTypeahead] = useState();
    const [, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [manufacturerDate,manufacturerDateInput, setManufacturerDate] = useInput({
        label: "Manufacturer Warranty End Date",
        type: "Date",
        initialValue: props.vehicle.manufacturer_main_warranty_date ? props.vehicle.manufacturer_main_warranty_date : null,
        onChange: (e) => {dispatch(createSetVehicleFieldAction({'manufacturer_main_warranty_date': e.target.value}))}
    })

    const [drivelineDate,drivelineDateInput, setDrivelineDate] = useInput({
        label: "Driveline Warranty End Date",
        type: "Date",
        initialValue: props.vehicle.driveline_warranty_date ? props.vehicle.driveline_warranty_date : null,
        onChange: (e) => {
            dispatch(createSetVehicleFieldAction({'driveline_warranty_date': e.target.value}))
        }
    })

    const [aftermarketDate,aftermarketDateInput, setAftermarketDate] = useInput({
        label: "After Market Warranty End Date",
        type: "Date",
        initialValue: props.vehicle.aftermarket_warranty_date ? props.vehicle.aftermarket_warranty_date : null,
        onChange: (e) => {dispatch(createSetVehicleFieldAction({'aftermarket_warranty_date': e.target.value}))}
    })

    const handleSearch = query => {
        setLoading(true);
        axios.get(props.endpoint+`contacts?&search=${query}`).then(resp => {
            setSearchData(resp.data.results);
            setLoading(false);
        });
    };

    return (
        <Box>
            <Collapsible
                className="title"
                triggerOpenedClassName="title"
                trigger="Warranty"
            >
                <Columns>
                    <Columns.Column>
                        <Form.Label>Manufacturer Warranty Supplier</Form.Label>
                        <AsyncTypeahead
                            isLoading={false}
                            id="typeahead"
                            labelKey="display_name"
                            minLength={2}
                            name={"display_name"}
                            onSearch={handleSearch}
                            onChange={(e) => {
                                dispatch(createSetVehicleFieldAction({'manufacturer_main_warranty_workshop': e[0]}))
                            }}
                            placeholder={`Search Supplier`}
                            options={searchData}
                            ref={typeahead => setTypeahead(typeahead)}
                            className="typeahead"
                        />
                        <br/>
                        <Form.Input
                            readOnly
                            disabled
                            value={props.vehicle.manufacturer_main_warranty_workshop ? props.vehicle.manufacturer_main_warranty_workshop.display_name : ""}
                        />
                        {manufacturerDateInput}
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Label>Driveline Warranty Supplier</Form.Label>
                        <AsyncTypeahead
                            isLoading={false}
                            id="typeahead"
                            labelKey="display_name"
                            minLength={2}
                            name={"display_name"}
                            onSearch={handleSearch}
                            onChange={(e) => {
                                dispatch(createSetVehicleFieldAction({'driveline_warranty_workshop': e[0]}))
                            }}
                            placeholder={`Search Supplier`}
                            options={searchData}
                            ref={typeahead => setTypeahead(typeahead)}
                            className="typeahead"
                        />
                        <br/>
                        <Form.Input
                            readOnly
                            disabled
                            value={props.vehicle.driveline_warranty_workshop ? props.vehicle.driveline_warranty_workshop.display_name : ""}
                        />
                        {drivelineDateInput}
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Label>After Market Warranty Supplier</Form.Label>
                        <AsyncTypeahead
                            isLoading={false}
                            id="typeahead"
                            labelKey="display_name"
                            minLength={2}
                            name={"display_name"}
                            onSearch={handleSearch}
                            onChange={(e) => {
                                dispatch(createSetVehicleFieldAction({'aftermarket_warranty_workshop': e[0]}))

                            }}
                            placeholder={`Search Supplier`}
                            options={searchData}
                            ref={typeahead => setTypeahead(typeahead)}
                            className="typeahead"
                        />
                        <br/>
                        <Form.Input
                            readOnly
                            disabled
                            value={props.vehicle.aftermarket_warranty_workshop ? props.vehicle.aftermarket_warranty_workshop.display_name : ""}
                        />
                        {aftermarketDateInput}
                    </Columns.Column>
                </Columns>
            </Collapsible>
        </Box>
    )
}
import { Section } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import React, { useState } from "react";
import { Modal } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { useSelector } from "react-redux";
import {getWorkLookupData} from "../../state/selectors";
import { Columns } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import {useWorkLookupData} from "../../hooks/useWorkLookupData";

export default function WorkSearchModal({
  modalOpen,
  currentSearch,
  searchText,
  selectWork,
  workIndex,
  setModalState,
  supplier
}) {
  const [page, setPage] = useState(1);
  let params = {
    page: page,
    search: currentSearch,
    supplier: supplier ? supplier.id : "",
    // view: "with_number",
    work_lookup_stock: true
  };

  const complete = useWorkLookupData(params, 1, !modalOpen);
  const data = useSelector(getWorkLookupData);

  const setModalOpen = val => {
    setModalState({
      modalOpen: val,
      currentSearch,
      searchText,
      workIndex,
    });
  };

  const setSearchText = val => {
    setModalState({
      searchText: val,
      currentSearch,
      modalOpen,
      workIndex,
    });
  };

  const setCurrentSearch = val => {
    setModalState({
      currentSearch: val,
      searchText,
      modalOpen,
      workIndex,
    });
  };

  return (
    <Modal closeOnBlur show={modalOpen} onClose={() => setModalOpen(false)}>
      <Modal.Content className="work-search-content">
        <Section style={{ backgroundColor: "grey" }}>
          <Box>
            <Heading>Works Selector</Heading>
            <Columns>
              <Columns.Column>
                <Form.Label>Work Search</Form.Label>
                <Form.Field className="has-addons">
                  <Form.Control>
                    <Form.Input
                      onChange={e => {
                        setSearchText(e.target.value);
                      }}
                      onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && setCurrentSearch(searchText)}
                      data-testid="work-search"
                      name="search_text"
                      type="text"
                      placeholder="Search"
                      value={searchText}
                    />
                  </Form.Control>
                  <Form.Control>
                    <Button
                      data-testid="search-button"
                      color="success"
                      onClick={() => {
                        setCurrentSearch(searchText);
                      }}
                    >
                      Search
                    </Button>
                  </Form.Control>
                  <Form.Control>
                    <Button
                      color="warning"
                      onClick={() => {
                        setModalState({
                          modalOpen,
                          workIndex,
                          currentSearch: "",
                          searchText: ""
                        });
                      }}
                    >
                      Clear
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Box>
          <Box>
            <Table>
              <thead>
                <tr>
                  <th>Work Description</th>
                  <th>Labour Hours</th>
                  <th>Labour Cost</th>
                  <th>Customer Labour Charge</th>
                  <th>Supplier</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {complete &&
                  data &&
                  data.results.map(work => (
                    <tr key={work.id}>
                      <td>{work.description}</td>
                      <td>{work.labour_hours}</td>
                      <td>
                        £
                        {(work.labour_cost || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </td>
                      <td>
                        £
                        {(work.customer_labour_cost || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </td>
                      <td>{work.supplier && work.supplier.display_name}</td>
                      <td>
                        <Button
                          onClick={() => selectWork(work, workIndex, workIndex)}
                          color="success"
                        >
                          Select
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              showFirstLast={true}
              onChange={setPage}
              current={page}
              total={Math.ceil(data ? data.count / 25 : 0)}
            ></Pagination>
          </Box>
        </Section>
      </Modal.Content>
    </Modal>
  );
}

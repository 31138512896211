import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import { readableDate } from "../../utils";
import React, {useRef, useState} from "react";
import axios from "axios";
import { createSetDataFreshAction } from "../../actions/dataActions";
import useSafeSave from "../../hooks/useSafeSave";
import { useDispatch, useSelector } from "react-redux";
import { getDataFresh } from "../../state/selectors";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import PrintJob from "./PrintJob";
import ReactToPrint from "react-to-print";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";


function JobRow({ job, endpoint, onJobUpdate, index, showApprover }) {
  const componentRef = useRef();
  const dateFields = ["book_in_date", "workshop_date", "finish_date"];
  const [editable, setEditable] = useState(false);
  const [lastEdited, setLastEdited] = useState();
  let dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const fixEmptyDates = job => {
    dateFields.forEach(function(date) {
      if (job[date] === "") {
        job[date] = null;
      }
    });
    let work_items = job.work_items;
    job.work_items = work_items.map(work => {
      let parts = work.parts;
      work.parts = parts.map(part => {
        if (part.date_ordered === "") {
          part.date_ordered = null;
        }
        return part;
      });
      return work;
    });
    return job;
  };

  const submitUpdate = () => {
    const url = endpoint + "jobs/" + job.id;
    const data = fixEmptyDates(job);
    const conf = {
      method: "put",
      data,
      url
    };

    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onSave = e => {
    return submitUpdate();
  };

  const checkUpdated = () => {
    return axios.get(endpoint + "jobs/" + job.id).then(newJob => {
      return newJob.data.last_saved === job.last_saved;
    });
  };

  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newJob = { ...job };
    newJob[e.target.name] = e.target.value;
    onJobUpdate(index, newJob);
  };

  return (
    <tr
      className="clickable small-row-black"
      onClick={() => window.open(`/editjob/${job.id}`, "_self")}
      key={job.id}
    >
      <td>{job.id}</td>
      <td>{job.vehicle.registration}</td>
      <td>{job.job_description}</td>
      <td>
      <Form.Field className="has-addons">
        <Form.Control>
          <Form.Textarea
            rows={2}
            className="small-row-black min-column-width"
            type="text"
            value={job.comments_list.length > 0 ? job.comments_list[job.comments_list.length -1].text : ""}
            disabled
            readOnly
          />
        </Form.Control>
      </Form.Field>
      </td>
      <td>{job.customer.display_name}</td>
      <td>{job.customer2 ? job.customer2.display_name : ""}</td>
      <td>{job.vehicle.make}</td>
      <td>{job.vehicle.model}</td>
      {useEditableTableDateCell({
        propName: "workshop_date",
        value: job.workshop_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableDateCell({
        propName: "book_in_date",
        value: job.book_in_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      <td>{job.workshop ? job.workshop.display_name : ""}</td>
      {showApprover &&
        <td>{job.approving_user ? `${job.approving_user.first_name} ${job.approving_user.last_name}` : ""}</td>
      }
      <td onClick={e => e.stopPropagation()}>
        <ReactToPrint
          trigger={() => (
            <Button color="warning">
              Print
            </Button>
          )}
          content={() => componentRef.current}
          bodyClass="print-landscape"
          pageStyle=""
        />
      </td>
      <div style={{ display: "none" }}>
        <div>
          <PrintJob data={job} ref={componentRef}></PrintJob>
        </div>
      </div>
    </tr>
  );
}

export default JobRow;

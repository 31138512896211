import React, { useState } from "react";

import InspectionRow from "./InspectionRow.js";

import { Box } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { createSetInspectionDataAction } from "../../actions/dataActions";
import { getDataFresh, getInspectionData } from "../../state/selectors";
import { useInspectionData } from "../../hooks/useInspectionData";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components"
import { Button } from "react-bulma-components";
import axios from "axios";
import { formatDate, readableDate } from "../../utils";

export default function Inspection(props) {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const dataFresh = useSelector(getDataFresh);
  const [filters, setFilters] = useState({});
  const [searchText, setSearchText] = useState("");
  const [currentSearch, setCurrentSearch] = useState("");

  let params = { page };

  if (currentSearch) {
    params.search = currentSearch;
  }

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const complete = useInspectionData(params, dataFresh);
  const data = useSelector(getInspectionData);

  const onInspectionUpdate = (index, newInspection) => {
    let newState = { ...data };
    newState.results[index] = newInspection;
    dispatch(createSetInspectionDataAction(newState));
  };

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const headings = [
    {
      label: "Registration",
      field: "vehicle__registration",
      labelField: "vehicle__registration"
    },
    {
      label: "Type",
      field: "inspection_type",
      labelField: "inspection_type"
    },
    {
      label: "Workshop",
      field: "inspection_workshop",
      labelField: "inspection_workshop__display_name"
    },
    {
      label: "Expiry Date",
      field: "expiry_date",
      labelField: "expiry_date",
      date: true
    },
    {
      label: "Last Completed",
      field: "date_completed",
      labelField: "date_completed",
      date: true
    },
    {
      label: "Comments"
    },
    {
      label: "Book In Date",
      field: "booked_in_date",
      labelField: "booked_in_date",
      date: true
    },
    {
      label: "New Completed Date",
      field: "new_completed_date",
      labelField: "new_completed_date",
      date: true
    },
    {
      label: "New Expiry Date",
      field: "new_expiry_date",
      labelField: "new_expiry_date",
      date: true
    }
  ];

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = "Inspections";

  return (
    <div>
      <Box>
        <h1 className="title">Inspections</h1>
        <Columns>
          <Columns.Column size={4}>
            <Form.Label>Search</Form.Label>
            <Form.Field className="has-addons">
              <Form.Control>
                <Form.Input
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && handleSearch()}
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                ></Form.Input>
              </Form.Control>
              <Form.Control>
                <Button onClick={handleSearch} type="primary">
                  Search
                </Button>
              </Form.Control>
              <Form.Control>
                <Button onClick={clear} color="warning">
                  Clear
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <h1 className="title">Upcoming Inspections</h1>
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr>
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <InspectionRow
                key={value.id}
                inspection={value}
                index={index}
                onInspectionUpdate={onInspectionUpdate}
                endpoint={props.endpoint}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          showFirstLast={true}
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        />
      </Box>
    </div>
  );
}

import React from "react";
import { Navbar } from "react-bulma-components";
import {useSelector} from "react-redux";
import {getCostCentresData, getDataFresh} from "../../state/selectors";
import {useCostCentreData} from "../../hooks/useCostCentreData";
import {useHistory} from "react-router";

function TransactionsNav({profile, transactionType, cost_centres}) {
  // const datafresh = useSelector(getDataFresh)
  const { push } = useHistory();
  const transMapping = {income: "Income", expenses: "Expenses"}
  // const cost_centres_loaded = useCostCentreData({}, datafresh, profile)
  // const cost_centre_data = useSelector(getCostCentresData);
  //
  // if (!cost_centres_loaded) {
  //   return null;
  // }

  // const cost_centres = cost_centre_data ? cost_centre_data.results : [];

  return <Navbar.Dropdown>
    <Navbar.Item onClick={() => push(`/${transactionType}`)}>
      All {transMapping[transactionType]}
    </Navbar.Item>
    {cost_centres.map((cc, i) => <Navbar.Item key={i} onClick={() => push(`/${transactionType}?cost_centre=${cc.id}`)}>
      {cc.name}
    </Navbar.Item>)}
  </Navbar.Dropdown>
}

export default TransactionsNav;

import Inspection from "../components/Inspections/View";

export const createSetAppDefaultsDataAction = data => ({
  type: "Data/SetAppDefaultsData",
  data
});

export const createSetJobsDataAction = data => ({
  type: "Data/SetJobsData",
  data
});

export const createSetQuotesDataAction = data => ({
  type: "Data/SetQuotesData",
  data
});

export const createSetPartsDataAction = data => ({
  type: "Data/SetPartsData",
  data
});
export const createSetDepartmentsDataAction = data => ({
  type: "Data/SetDepartmentsData",
  data
})
export const createSetPartLookupDataAction = data => ({
  type: "Data/SetPartLookupData",
  data
});

export const createSetWorkLookupDataAction = data => ({
  type: "Data/SetWorkLookupData",
  data
});

export const createSetRentalDataAction = data => ({
  type: "Data/SetRentalData",
  data
});

export const createSetUserDataAction = data => ({
  type: "Data/SetUserData",
  data
});

export const createSetTransactionDataAction = data => ({
  type: "Data/SetTransactionData",
  data
});

export const createSetWeeklyTransactionDataAction = data => ({
  type: "Data/SetWeeklyTransactionData",
  data
});

export const createSetDataFreshAction = data => ({
  type: "DataFresh/SetDataFresh",
  dataFresh: data
});

export const createSetPartsFiguresDataAction = data => ({
  type: "Data/SetPartsFiguresData",
  data
});

export const createSetWorkFiguresDataAction = data => ({
  type: "Data/SetWorkFiguresData",
  data
});

export const createSetTransactionFiguresDataAction = data => ({
  type: "Data/SetTransactionFiguresData",
  data
});

export const createSetRentalFiguresDataAction = data => ({
  type: "Data/SetRentalFiguresData",
  data
});

export const createSetVehicleFiguresDataAction = data => ({
  type: "Data/SetVehicleFiguresData",
  data
});

export const createSetTargetFiguresDataAction = data => ({
  type: "Data/SetTargetFiguresData",
  data
});

export const createSetTargetsDataAction = data => ({
  type: "Data/SetTargetsData",
  data
});

export const createSetInspectionTypesDataAction = data => ({
  type: "Data/SetInspectionTypesData",
  data
});

export const createSetInspectionDataAction = data => ({
  type: "Data/SetInspectionData",
  data
});

export const createSetVehicleTypesDataAction = data => ({
  type: "Data/SetVehicleTypesData",
  data
});

export const createSetTransactionCategoriesDataAction = data => ({
  type: "Data/SetTransactionCategoriesData",
  data
});

export const createSetTransactionReportingCategoriesDataAction = data => ({
  type: "Data/SetTransactionReportingCategoriesData",
  data
});

export const createSetCostCentresDataAction = data => ({
  type: "Data/SetCostCentresData",
  data
});

export const createSetVehicleDataAction = data => ({
  type: "Data/SetVehicleData",
  data
});

export const createSetVehicleTrackersDataAction = data => ({
  type: "Data/SetVehicleTrackersData",
  data
});

export const createSetVehicleFieldAction = data => ({
  type: "Data/SetVehicleField",
  data
});

export const createSetVehicleSummaryDataAction = data => ({
  type: "Data/SetVehicleSummaryData",
  data
});

export const createSetJobFieldAction = data => ({
  type: "Data/SetJobField",
  data
});

export const createSetQuoteFieldAction = data => ({
  type: "Data/SetQuoteField",
  data
});

export const createSetJobAction = data => ({
  type: "Data/SetJobData",
  data
});

export const createSetQuoteAction = data => ({
  type: "Data/SetQuoteData",
  data
});

export const createSetDiaryDataAction = data => ({
  type: "Data/SetDiaryData",
  data
});

export const createSetWorkDataAction = data => ({
  type: "Data/SetWorkData",
  data
});

export const createSetVehiclesDataAction = data => ({
  type: "Data/SetVehiclesData",
  data
});

export const createSetFinanceDataAction = data => ({
  type: "Data/SetFinanceData",
  data
});

export const createSetContactDataAction = data => ({
  type: "Data/SetContactData",
  data
});

export const createSetContactsDataAction = data => ({
  type: "Data/SetContactsData",
  data
});

export const createSetBlankContactDataAction = data => ({
  type: "Data/SetBlankContactData",
  data
});

export const createSetPaymentsDataAction = data => ({
  type: "Data/SetPaymentsData",
  data
});

export const createSetRecurringPaymentsDataAction = data => ({
  type: "Data/SetRecurringPaymentsData",
  data
});

export const createSetQuickLinksDataAction = data => ({
  type: "Data/SetQuickLinksData",
  data
});
import React from "react";



export default function PrintWashRow({job}){
    const pickCustomer = (job) =>{
        return job.customer2 ? job.customer2.display_name : job.customer.display_name
    }
    return(
        <div>
            <tr>
                <td data-testid={"vehicle-reg"}>{job.vehicle.registration}</td>
                <td data-testid={"vehicle-make"}>{job.vehicle.make}</td>
                <td data-testid={"vehicle-model"}>{job.vehicle.model}</td>
                <td data-testid={"customer"}>{pickCustomer(job)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </div>
    )





}
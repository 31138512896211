import React, { useState } from "react";
import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import WorkTotalsSection from "../WorkTotals/WorkTotalsSection";
import { Heading } from "react-bulma-components";
import WorkTotals from "../WorkTotals/WorkTotals";
import { Button } from "react-bulma-components";
import Figure from "../Homepage/Figure";
import { Link } from "react-router-dom";

function TopSummary({
  job,
  customer_label,
  customer2_label,
  quote,
  onSave,
  itemType,
  handleChange
}) {
  const items = job.work_items ? job.work_items : job.estimated_work_items;

  const startField =
    itemType === "Job" ? "start_date" : "provisional_start_date";
  const approvedField =
    itemType === "Job" ? "approved" : "ready_for_customer_invoice";

  let status =
    new Date(job[startField]) < new Date() ? "In Progress" : "Not Started";

  if (itemType === "Quote" && job.finalized === true) {
    status = "Finalized";
  }

  status = job[approvedField] ? "Approved" : status;

  return (
    <Box className={"top-summary"}>
      <Columns>
        {itemType === "Job" && quote && (
          <Columns.Column className="top-summary-section">
            <Heading size={6}>
              {customer2_label} Estimated Total (Full Quote)
            </Heading>
            <Columns className="small-figure">
              <Figure
                label="Labour"
                loaded={true}
                value={job.full_quote_customer2_estimated_labour_total}
              />
            </Columns>
            <Columns className="small-figure">
              <Figure
                label="Parts"
                loaded={true}
                value={job.full_quote_customer2_estimated_parts_total}
              />
            </Columns>
              <Columns className="small-figure">
              <Figure
                label="Total"
                loaded={true}
                value={
                  job.full_quote_customer2_estimated_labour_total +
                  job.full_quote_customer2_estimated_parts_total
                }
              />
            </Columns>
          </Columns.Column>
        )}
        {itemType === "Job" && quote && (
          <Columns.Column className="top-summary-section">
            <Heading size={6}>
              {customer2_label} Actual Costs (Full Quote)
            </Heading>
            <Columns className="small-figure">
              <Figure
                label="Labour"
                loaded={true}
                value={job.full_quote_customer2_actual_labour_total}
              />
            </Columns>
              <Columns className="small-figure">
              <Figure
                label="Parts"
                loaded={true}
                value={job.full_quote_customer2_actual_parts_total}
              />
            </Columns>
                <Columns className="small-figure">
              <Figure
                label="Total"
                loaded={true}
                value={
                  job.full_quote_customer2_actual_labour_total +
                  job.full_quote_customer2_actual_parts_total
                }
              />
            </Columns>
          </Columns.Column>
        )}
        {quote && (
          <Columns.Column className="top-summary-section">
            <Heading size={6}>Estimated Supplier Costs</Heading>
            <WorkTotals
              workItems={items}
              allItems={true}
              labourFieldName="estimated_labour_cost"
              partFieldName="estimated_cost"
            />
          </Columns.Column>
        )}
        {quote && (
          <Columns.Column className="top-summary-section">
            <Heading size={6}>Estimated {customer_label} Total</Heading>
            <WorkTotals
              workItems={items}
              allItems={false}
              chargeable={false}
              labourFieldName="estimated_customer_labour_cost"
              partFieldName="estimated_customer_cost"
            />
          </Columns.Column>
        )}
        {quote && (
          <Columns.Column className="top-summary-section">
            <Heading size={6}>Estimated {customer2_label} Total</Heading>
            <WorkTotals
              workItems={items}
              allItems={false}
              chargeable={true}
              labourFieldName="estimated_customer_labour_cost"
              partFieldName="estimated_customer_cost"
            />
          </Columns.Column>
        )}
        <Columns.Column className="top-summary-section">
          <Heading size={6}>Total Supplier Costs</Heading>
          <WorkTotals
            workItems={items}
            allItems={true}
            labourFieldName="labour_cost"
            partFieldName="cost"
          />
        </Columns.Column>
        <Columns.Column className="top-summary-section">
          <Heading size={6}>{customer_label} Total</Heading>
          <WorkTotals
            workItems={items}
            allItems={false}
            chargeable={false}
            labourFieldName="customer_labour_cost"
            partFieldName="customer_cost"
          />
        </Columns.Column>
        <Columns.Column>
          <Heading size={6}>{customer2_label} Total</Heading>
          <WorkTotals
            workItems={items}
            allItems={false}
            chargeable={true}
            labourFieldName="customer_labour_cost"
            partFieldName="customer_cost"
          />
        </Columns.Column>
        <Columns.Column>
          <Heading size={6}>Custom</Heading>
          <Columns>
            <Columns.Column>
              <Form.Input
                tabIndex={1}
                type="text"
                value={job.custom_label_1}
                name="custom_label_1"
                onChange={handleChange}
              />
            </Columns.Column>
            <Columns.Column>
              <Form.Input
                tabIndex={2}
                type="text"
                value={job.custom_value_1}
                name="custom_value_1"
                onChange={handleChange}
              />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Input
                tabIndex={3}
                type="text"
                value={job.custom_label_2}
                name="custom_label_2"
                onChange={handleChange}
              />
            </Columns.Column>
            <Columns.Column>
              <Form.Input
                tabIndex={4}
                type="text"
                value={job.custom_value_2}
                name="custom_value_2"
                onChange={handleChange}
              />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Input
                tabIndex={5}
                type="text"
                value={job.custom_label_3}
                name="custom_label_3"
                onChange={handleChange}
              />
            </Columns.Column>
            <Columns.Column>
              <Form.Input
                tabIndex={6}
                type="text"
                value={job.custom_value_3}
                name="custom_value_3"
                onChange={handleChange}
              />
            </Columns.Column>
          </Columns>
        </Columns.Column>
      </Columns>
      <Columns>
        <Columns.Column>
          <Form.Field>
            <label>Registration</label>
            <Form.Control className="registration-wrapper">
              <Form.Input
                className="registration-field"
                type="text"
                name="registration"
                value={job.vehicle ? job.vehicle.registration : ""}
                disabled
                readOnly
                required
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <label>VIN Number</label>
            <Form.Control>
              <Form.Input
                type="text"
                name="vin_number"
                value={job.vehicle ? job.vehicle.vin_number : ""}
                readOnly
                disabled
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <label>First Registration Date</label>
            <Form.Control>
              <Form.Input
                type="date"
                name="first_registration_date"
                value={job.vehicle ? job.vehicle.first_registration_date : ""}
                readOnly
                disabled
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <label>{itemType} No.</label>
            <Form.Input
              type="text"
              value={job.id || ""}
              readOnly
              disabled
            />
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <label>Workshop</label>
            <Form.Input
              type="text"
              name="display_name"
              value={job.workshop ? job.workshop.display_name : ""}
              readOnly
              disabled
            />
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <label>{itemType} Status</label>
            <Form.Input
              className="has-text-weight-bold"
              type="text"
              value={status}
              readOnly
              disabled
            />
          </Form.Field>
        </Columns.Column>
        {status === 'Approved' && job.approving_user &&
        <Columns.Column>
          <Form.Field>
            <label>Approver</label>
            <Form.Input
              className="has-text-weight-bold"
              type="text"
              value={`${job.approving_user.first_name} ${job.approving_user.last_name}` }
              readOnly
              disabled
            />
          </Form.Field>
        </Columns.Column>
        }
        {itemType === "Job" && quote && (
          <Columns.Column>
            <Form.Field>
              <Link to={`/editquote/${quote}`} target="_self">
                <Button tabIndex={-1} color="warning" className="is-pulled-right button-spacing">
                  Go To Quote
                </Button>
              </Link>
            </Form.Field>
          </Columns.Column>
        )}
        <Columns.Column>
          <Form.Field>
            <Button tabIndex={-1} onClick={onSave} color="info" className="is-pulled-left button-spacing">
              Save {itemType}
            </Button>
          </Form.Field>
        </Columns.Column>
      </Columns>
    </Box>
  );
  // return <Box>
}

export default TopSummary;

import React, {useState} from "react";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import { Button } from "react-bulma-components";
import {readableDate} from "../../utils";
import useEditableTableNumberCell from "../../hooks/useEditableTableNumberCell";
import useSafeSave from "../../hooks/useSafeSave";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useEditableTableCostCell from "../../hooks/useEditableTableCostCell";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import useEditableTableDropdownCell from "../../hooks/useEditableTableDropdownCell";
import { Form } from "react-bulma-components";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import FAIcon from "../Icon/FAIcon";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function WorkLookupRow({workLookup, endpoint, updateValue, vehicleTypes}) {
  let dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [, setTypeahead] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [lastEdited, setLastEdited] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);


  const onSave = e => {
    const url = endpoint + "worklookup/update/" + workLookup.id;
    const conf = {
      method: "put",
      data: workLookup,
      url
    };
    setEditable(false);

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };


  const handleDelete = e => {
    const url = endpoint + "worklookup/update/" + workLookup.id;
    const conf = {
      method: "delete",
      url
    };
    setEditable(false);

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  }

  const checkUpdated = () => {
    return axios
      .get(endpoint + "worklookup/update/" + workLookup.id)
      .then(
        checkWorkLookup =>
          (new Date(checkWorkLookup.data.last_saved).getTime() -
            new Date(workLookup.last_saved).getTime()) /
          1000 <
          1
      );
  };


  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newWorkLookup = {...workLookup};
    newWorkLookup[e.target.name] = e.target.value;
    updateValue(newWorkLookup);
  };

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  }

  const handleWorkSupplierSelect = supplier => {
    let newWorkLookup = {...workLookup}
    newWorkLookup.supplier = supplier[0];
    updateValue(newWorkLookup);
  };


  const saveAfterButtonClick = () => {
    const url = endpoint + "worklookup/update/" + workLookup.id;
    const conf = {
      method: "put",
      data: workLookup,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const buttonClick = prop => {
    workLookup[prop] = !workLookup[prop];
    saveAfterButtonClick();
  };

  return (
    <tr key={workLookup.id}>
      {useEditableTableTextCell({
          propName: "description",
          value: workLookup.description,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        }
      )}
      {useEditableTableNumberCell({
        propName: "labour_hours",
        value: workLookup.labour_hours,
        onChange: onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableCostCell({
        propName: "labour_cost",
        value: workLookup.labour_cost,
        onChange: onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableCostCell({
        propName: "customer_labour_cost",
        value: workLookup.customer_labour_cost,
        onChange: onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      <td>
        <Form.Field className="has-addons">
          <Form.Control>
            <AsyncTypeahead
              id="typeahead"
              labelKey="display_name"
              minLength={2}
              name={"supplier"}
              onSearch={handleSearch}
              onChange={handleWorkSupplierSelect}
              placeholder={"Search Supplier"}
              disabled={!editable}
              options={searchData}
              ref={typeahead => setTypeahead(typeahead)}
              className="typeahead"
              isLoading={isLoading}
              selected={workLookup.supplier && workLookup.supplier.id ? [workLookup.supplier] : []}
            />
          </Form.Control>
          {editable ? (
            <Form.Control>
              <Button
                className="small-row-white"
                color="success"
                onClick={onSave}
              >
                <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
              </Button>
            </Form.Control>
          ) : (
            <Form.Control>
              <Button
                className="small-row-black"
                onClick={() => setEditable(!editable)}
              >
                <FAIcon size="small" icon={["fas", "edit"]}/>
              </Button>
            </Form.Control>
          )}
        </Form.Field>
      </td>
      <td className="float-right">
        <Button
          color="danger"
          data-testid={`submit-work-delete${workLookup.id}`}
          onClick={setDeleteModalOpen}
        >
          Delete
        </Button>
      </td>
      <DeleteWarningModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onDelete={handleDelete}
      />
    </tr>
  );
}

import React from "react";
import {useVehicleInspectionData} from "../../hooks/useVehicleInspectionData";
import {useSelector} from "react-redux";
import {getInspectionData} from "../../state/selectors";
import {Table} from "react-bulma-components";
import VehicleInspectionRow from "./VehicleInspectionRow";

export default function Inspections(props) {
  let complete = useVehicleInspectionData("1017")
  let data = useSelector(getInspectionData);

  if (complete) {
    return (
      <Table bordered>
        <thead>
        <tr>
          <th className={"table-header-bold"}>Inspection Type</th>
          <th className={"table-header-bold"}>Inspection Workshop</th>
          <th className={"table-header-bold"}>Expiry Date</th>
        </tr>
        </thead>
        <tbody>
        {data.results.map(inspection => (<VehicleInspectionRow
            inspection={inspection}
          />))}
        </tbody>
      </Table>)
  } else {
    return (<Table bordered>
        <thead>
        <tr>
          <td>Inspection Type</td>
          <td>Inspection Workshop</td>
          <td>Expiry Date</td>
        </tr>
        </thead>
      </Table>)
  }

}
import React from "react";
import { Table } from "react-bulma-components";
import { readableDate } from "../../utils";
import logo from "../Nav/img/Logo.jpg";
import vanImage from "./images/van.png";
import { Columns } from "react-bulma-components";

class PrintVehicleCheckInSheet extends React.Component {

  render() {
    const job = this.props.data;
    const {customer, customer2, vehicle} = job;
    const displayCustomer = customer2 ? customer2 : customer

    return (
      <>
        <div className="page">
          <Columns className="page" >
            <Columns.Column>

              <Table bordered size={"fullwidth"}>
                <colgroup>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                </colgroup>
                <tbody>
                <tr>
                  <td colSpan={12} className="table-title">
                    <img src={logo} alt="Rentals Direct Ltd"/><span>VEHICLE CHECK-IN SHEET</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className="cell-header">REGISTRATION:</td>
                  <td colSpan={3} data-testid={"vehicle-reg"}>{vehicle.registration ?? ''}</td>
                  <td colSpan={3} className="cell-header">MAKE / MODEL:</td>
                  <td colSpan={3} data-testid={"vehicle-make/model"}>{vehicle.make} - {vehicle.model}</td>
                </tr>
                <tr>
                  <td colSpan={3} className="cell-header">VIN:</td>
                  <td colSpan={3} data-testid={"vehicle-vin"}>{vehicle.vin_number ?? ''}</td>
                  <td colSpan={3} className="cell-header">D.O.R:</td>
                  <td colSpan={3} data-testid={"vehicle-reg-date"}>{readableDate(vehicle.first_registration_date) ?? ''}</td>
                </tr>
                <tr>
                  <td colSpan={3} className="cell-header">CUSTOMER NAME:</td>
                  <td
                    colSpan={9} data-testid={"customer-name"}>{displayCustomer.display_name}</td>
                </tr>
                <tr>
                  <td colSpan={3} className="cell-header">ADDRESS:</td>
                  <td colSpan={9} data-testid={"address"}>{displayCustomer.address_line1}{`\n`}{displayCustomer.town}</td>
                </tr>
                <tr>
                  <td colSpan={3} className="cell-header">POSTCODE:</td>
                  <td colSpan={3} data-testid={"postcode"}>{displayCustomer.postcode ?? ''}</td>
                  <td colSpan={3} className="cell-header">PHONE NUMBER:</td>
                  <td colSpan={3} data-testid={"customer-phone"}>{displayCustomer.company_phone ?? ''}</td>
                </tr>
                <tr>
                  <td colSpan={3} className="cell-header">EMAIL ADDRESS:</td>
                  <td colSpan={9} data-testid={"customer-email"}>{displayCustomer.email ? displayCustomer.email : displayCustomer.company_email ?? ''}</td>
                </tr>
                <tr>
                  <td colSpan={12} className="table-sub-title cell-header">INSTRUCTIONS / REPORTED DEFECTS / BOOKING IN
                    FOR...
                  </td>
                </tr>
                <tr>
                  <td colSpan={12} className="instructions">
                    <ul>
                      {job.work_items.map((work, i) => <li key={i} data-testid={"work-"+i}>{work.description}</li>)}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className="cell-header">MILEAGE:</td>
                  <td colSpan={3}></td>
                  <td colSpan={3} className="cell-header">PRICE QUOTED:</td>
                  <td colSpan={3}></td>
                </tr>
                <tr>
                  <td colSpan={3} className="cell-header" rowSpan={2}>WORKSHOP DATE:</td>
                  <td colSpan={3} rowSpan={2} data-testid={"workshop-date"}>{readableDate(job.workshop_date)}</td>
                  <td colSpan={3} className="cell-header">DROP OFF ETA:</td>
                  <td colSpan={3} data-testid={"drop-eta"}>{job.drop_off_eta ?? ''}</td>
                </tr>
                <tr>
                  <td colSpan={3} className="cell-header">COLLECTION ETA:</td>
                  <td colSpan={3} data-testid={"collection-eta"}>{job.collection_eta ?? ''}</td>
                </tr>
                <tr>
                  <td colSpan={12} className="table-sub-title cell-header">COMMENTS & OTHER NOTES</td>
                </tr>
                <tr>
                  <td colSpan={12} className="comments"></td>
                </tr>
                </tbody>
              </Table>
            </Columns.Column>
          </Columns>
          <Columns className="page">
            <Columns.Column>
              <Table bordered size={"fullwidth"}>
                <colgroup>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                  <col span="1" style={{width: '8.333%'}}/>
                </colgroup>
                <tbody>
                <tr>
                  <td colSpan={12} className="table-sub-title cell-header">REPORTED DAMAGE TO VEHICLE</td>
                </tr>
                <tr>
                  <td colSpan={12} className="vehicle-image">
                    <img src={vanImage}/>
                  </td>
                </tr>
                <tr>
                  <td colSpan={12} className="terms">
                    I HEREBY AGREE FOR MY VEHICLE TO BE WASHED AND/OR DRIVEN BY EMPLOYEES OF RENTALS DIRECT.<br/>
                    WE CANNOT BE HELD RESPONSIBLE FOR ANY DAMAGE CAUSED BY WASHING YOUR VEHICLE OR BY BEING PARKED IN OUR CAR
                    PARK.<br/>
                    ALL VALUABLES ARE LEFT IN THE VEHICLE AT THE OWNER'S RISK.<br/>
                    YOUR VEHICLE WILL BE CHECKED IN BY A MEMBER OF OUR STAFF AND PHOTOS TAKEN UPON ARRIVAL OF YOUR
                    VEHICLE.
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} className="table-sub-title cell-header sign-box">
                    SIGNED BY CUSTOMER:
                  </td>
                  <td colSpan={8} className="sign-box"></td>
                </tr>
                <tr>
                  <td colSpan={4} className="table-sub-title cell-header sign-box">
                    PRINT NAME:
                  </td>
                  <td colSpan={8} className="sign-box"></td>
                </tr>
                <tr>
                  <td colSpan={12} className="terms">
                    RENTALS DIRECT TERMS & CONDITIONS APPLY - ANY CHARGES LISTED ARE EXCLUDING VAT @ 20%
                  </td>
                </tr>
                </tbody>
              </Table>
            </Columns.Column>
          </Columns>
        </div>
      </>
    );
  }
}

export default PrintVehicleCheckInSheet;

import { Form } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import React, { useState } from "react";
import axios from "axios";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

function useTypeaheadInput({ label, placeholder, searchUrl, initialValue, size, resultsMapper}) {
  const [value, setValue] = useState(initialValue);
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [, setTypeahead] = useState();

  const handleSearch = e => {
    setLoading(true);
    axios.get(`${searchUrl}&search=${e}`).then(resp => {
      setSearchData(resultsMapper ? resp.data.results.map(resultsMapper) : resp.data.results);
      setLoading(false);
    });
  };

  const handleValueSelect = inputValue => {
    setValue(inputValue[0]);
  };

  const input = (
    <Columns.Column size={size}>
      <Form.Field>
        <Form.Label>{label}</Form.Label>
        <Form.Control>
          <AsyncTypeahead
                id="typeahead"
                labelKey="display_name"
                minLength={2}
                name={label}
                onSearch={handleSearch}
                onChange={handleValueSelect}
                placeholder={placeholder}
                options={searchData}
                ref={typeahead => setTypeahead(typeahead)}
                className="typeahead"
                isLoading={isLoading}
                selected={
                  value && value.id ? [value] : []
                }
            />
        </Form.Control>
      </Form.Field>
    </Columns.Column>
  );
  return [value, input, setValue];
}

export default useTypeaheadInput;

import React, {useEffect, useMemo, useState} from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import {NotificationManager} from "react-notifications";
import { Button } from "react-bulma-components";
import useInput from "../../hooks/useInput";
import {
  intervalTypes,
  paymentTypes,
} from "./types";
import RecurringPaymentPreview from "./RecurringPaymentPreview";
import useSelect from "../../hooks/useSelect";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function CreateRecurringPaymentModal({open, setOpen, endpoint, costCentres, costCentreObjects}) {
  const dispatch = useDispatch();
  const dataFresh = useSelector(getDataFresh);
  const [, setTypeahead] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  // Form data
  const [contact, setContact] = useState();
  const [paymentType, setPaymentType] = useState();
  const [intervalType, setIntervalType] = useState();

  const [startDate, startDateInput, setStartDate] = useInput({
    type: "date",
    label: "Start Date",
    initialValue: null,
  });

  const [endDate, endDateInput, setEndDate] = useInput({
    type: "date",
    label: "End Date",
    initialValue: null
  })

  const [paymentAmount, paymentAmountInput, setPaymentAmount] = useInput({
    type: "number",
    currency: true,
    label: "Payment Amount",
    initialValue: null
  });

  const [intervalDay, intervalDaysInput, setIntervalDay] = useInput({
    type: "number",
    label: "Every X Days",
    initialValue: null
  });
  const [costCentre, setCostCentre] = useState(costCentres[0])

  const [comments, commentsInput, setComments] = useInput({
    label: "Comments",
    type: "text",
    initialValue: null
  })

  const clearForm = () => {
    setContact(undefined);
    setPaymentType(undefined);
    setIntervalType(undefined);
    setIntervalDay(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setPaymentAmount(undefined);
    setComments(undefined);
    setCostCentre(undefined);
  }
  const onCCChange = (e) => {
    setCostCentre(costCentreObjects[e.target.value])
  };
  // Reset interval day when interval type changes
  useEffect(() => {
    setIntervalDay(null);
  }, [intervalType]);

  const checkFieldValidation = () => {
    // startDate
    const startDateDate = new Date(startDate);
    const todayDateString = new Date().toISOString().slice(0, 10);
    const today = new Date(todayDateString);
    if (startDateDate < today) {
      NotificationManager.error("Minimum start date is today", "Error!", 10000);
      setStartDate(todayDateString);
      return false;
    }

    // intervalDay
    if (intervalDay && intervalDay < 7) {
      NotificationManager.error("Minimum payment interval is 7 days", "Error!", 10000);
      setIntervalDay(7);
      return false;
    }

    return true;
  }

  const onClickAction = () => {
    if (checkFieldValidation() && checkAllFieldsFilled()) {
      save();
      clearForm();
      setOpen(false);
    } else {
      NotificationManager.error("Please fill in all fields", "Error!", 10000);
    }
  };

  const handleContactSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?search=${e}`).then(resp => {
      setSearchData(resp.data.results.map(contact => ({
        ...contact,
        display_name: `${contact.display_name}`
      })));
      setLoading(false);
    });
  }

  const handleContactSelect = contacts => {
    setContact(contacts[0]);
  };

  const checkAllFieldsFilled = () => {
    if (!contact || !startDate || !paymentType || !intervalType || !paymentAmount) {
      return false;
    }

    if (intervalType === 'FIXED_DURATION' && !intervalDay) {
      return false;
    }

    return true;
  }

  const showPreview = useMemo(() => {
    if (intervalType === 'FIXED_DURATION') {
      return !!startDate && !!intervalType && !!intervalDay
    }
    return !!startDate && !!intervalType
  }, [intervalType, intervalDay, startDate])

  const handlePaymentTypeUpdate = (e) => {
    setPaymentType(e.target.value);
  }

  const handleIntervalTypeUpdate = (e) => {
    setIntervalType(e.target.value);
  }

  // Save an existing tracker
  const save = () => {
    const url = endpoint + "recurringpayments";
    const data = {
      contact,
      start_date: startDate,
      end_date: endDate,
      interval_payment_amount: Number(paymentAmount),
      payment_type: paymentType,
      interval_type: intervalType,
      interval_day: Number(intervalDay),
      cost_centre: costCentre,
      comments: comments
    };

    const conf = {
      method: "post",
      data,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
      setOpen(false);
    });
  };

  return (
    <Modal
      closeOnBlur
      show={open}
      nClick={e => {
        e.stopPropagation()
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content className="wider-modal">
        <Section style={{backgroundColor: "white"}}>
          <Heading className="is-centered">Create New Recurring Payment</Heading>
          <p className="is-centered">* Leave end date blank for recurring payments that repeat indefinitely<br/><br/>
          </p>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Contact</Form.Label>
                <Form.Control>
                  <AsyncTypeahead
                    id="typeahead"
                    labelKey="display_name"
                    minLength={2}
                    name={"contact"}
                    onSearch={handleContactSearch}
                    onChange={handleContactSelect}
                    placeholder={"Search Contacts"}
                    options={searchData}
                    ref={typeahead => setTypeahead(typeahead)}
                    className="typeahead"
                    isLoading={isLoading}
                    selected={contact && contact.id ? [contact] : []}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Payment Type</Form.Label>
                  <Form.Select
                    onChange={handlePaymentTypeUpdate}
                    name="payment_type"
                    value={paymentType}
                  >
                    <option key={-1} value={undefined}></option>
                    {paymentTypes.map(value =>
                      <option key={value.id} value={value.id}>
                        {value.name}
                      </option>
                    )}
                  </Form.Select>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            {startDateInput}
            {endDateInput}
          </Columns>
          <Columns>
            <Columns.Column>
              <Columns>
                <Columns.Column>
                  <Form.Field>
                    <Form.Control>
                      <Form.Label>Interval Type</Form.Label>
                      <Form.Select
                        onChange={handleIntervalTypeUpdate}
                        name="interval_type"
                        value={intervalType}
                      >
                        <option key={-1} value={undefined}></option>
                        {intervalTypes.map(value =>
                          <option key={value.id} value={value.id}>
                            {value.name}
                          </option>
                        )}
                      </Form.Select>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                {intervalType && intervalType === 'FIXED_DURATION' && intervalDaysInput}
              </Columns>
            </Columns.Column>
            <Columns.Column>
              <td>
                <Form.Control>
                  <Form.Select
                      name="cost_centre"
                      value={costCentre ? costCentre.value : costCentres[0].value}
                      onChange={e => onCCChange(e)}
                  >
                    {costCentres.map(value => (
                        <option key={value.id} value={value.id}>
                          {value.name}
                        </option>
                    ))}
                  </Form.Select>
                </Form.Control>
              </td>
            </Columns.Column>
            {paymentAmountInput}
            {commentsInput}
          </Columns>
          {
            showPreview &&
            <RecurringPaymentPreview
              startDate={startDate}
              endDate={endDate}
              intervalType={intervalType}
              intervalDays={intervalDay}
              paymentAmount={paymentAmount}
              maxRows={5}
            />
          }
          <Columns>
            <Columns.Column>
              <Button
                color="info"
                fullwidth
                onClick={() => {
                  clearForm();
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button color="danger" fullwidth onClick={onClickAction}>
                Save Recurring Payment
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}

import React, {useRef, useState} from "react";
import {Box, Section} from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { Tabs } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import {formatDate, readableDate} from "../../utils";
import {getContactsData, getDataFresh, getDepartmentsData, getJobsData} from "../../state/selectors";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import { useJobsData } from "../../hooks/useJobsData";
import Figures from "./Figures";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useSafeSave from "../../hooks/useSafeSave";
import axios from "axios";
import DepartmentPicker from "./DepartmentPicker";
import {
  createSetDataFreshAction,
  createSetJobsDataAction,
  createSetPartsDataAction
} from "../../actions/dataActions";
import JobRow from "./JobRow";
import PrintJob from "./PrintJob";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import ReactToPrint from "react-to-print";
import PrintListWash from "./PrintListWash";
import {useWorkshopsData} from "../../hooks/useWorkshopsData";
import {useDepartmentsData} from "../../hooks/useDepartmentsData";

function Job(props) {
  const [page, setPage] = useState(1);
  const [date, setDate] = useState();
  const [activeView, setView] = useState("started");
  const [activeTab, setTab] = useState("all");
  const [searchText, setSearchText] = useState();
  const [currentSearch, setCurrentSearch] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);
  const componentRef = useRef();

  let params = { page, view: activeView, date: date};
  const [filters, setFilters] = useState({});
  const headings = [
    {
        label: "Job no.",
    },
    {
        label: "Registration",
        field: "vehicle",
        labelField: "vehicle__registration"
    },
    {
        label: "Description"
    },
    {
        label: "Comments"
    },
    {
        label: "Customer",
        field: "customer",
        labelField: "customer__display_name"
    },
    {
        label: "Customer2",
    },
    {
        label: "Make"
    },
    {
        label: "Model"
    },
    {
        label: "Workshop Date",
        field: "workshop_date",
        labelField: "workshop_date",
        date : true
    },
    {
        label: "Book in Date",
        field: "book_in_date",
        labelField: "book_in_date",
        date: true,
        noBlank: true
    },
    {
        label: "Workshop"
    }
  ];

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  if (activeTab) {
    params.ws = activeTab;
  }

  if (currentSearch) {
    params.search = currentSearch;
  }
  if (selectedDepartment){
    params.department = selectedDepartment;
  }
  const complete = useJobsData(params, dataFresh);
  const departmentsComplete = useDepartmentsData({}, dataFresh)
  const departments = useSelector(getDepartmentsData)
  const data = useSelector(getJobsData);
  const completeTabs = useWorkshopsData({}, dataFresh);
  const tabs = useSelector(getContactsData);
  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
  };

  const setNewView = (view) => {
    setPage(1)
    setView(view)
    setDate()
  };

  const setNewTab = (tab) => {
    setPage(1)
    setTab(tab)
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };
  const onDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  }
  const onJobUpdate = (index, newJob) => {
    let newState = { ...data };
    newState.results[index] = newJob;
    dispatch(createSetJobsDataAction(newState));
  };

  if (!complete || !completeTabs || !departmentsComplete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = "Jobs";

  return (
    <div>
      <Box>
        <Columns>
          <Columns.Column>
            <Form.Label>Search for a Job</Form.Label>
            <Form.Field className="has-addons">
              <Form.Control>
                <Form.Input
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && handleSearch()}
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                ></Form.Input>
              </Form.Control>
              <Form.Control>
                <Button onClick={handleSearch} type="primary">
                  Search
                </Button>
              </Form.Control>
              <Form.Control>
                <Button onClick={clear} color="warning">
                  Clear
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
           <DepartmentPicker
            onDepartmentChange={onDepartmentChange}
            selectedDepartment={selectedDepartment}
            departments={departments}
           />
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Button
                  onClick={() => props.history.push("/editjob")}
                  color="success"
                  className="is-pulled-right"
                >
                  New Job +
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Figures department={selectedDepartment}/>
      <Box>
        <h1 className="title">Jobs</h1>
        <Tabs type="boxed" fullwidth align="centered">
          <Tabs.Tab active={activeTab === "all"} onClick={() => setNewTab("all")}>
            All
          </Tabs.Tab>
          <Tabs.Tab
            active={activeTab === "quotes"}
            onClick={() => setNewTab("quotes")}
          >
            Quote Jobs
          </Tabs.Tab>
          <Tabs.Tab active={activeTab === "external"} onClick={() => setNewTab("external")}>
            External
          </Tabs.Tab>
          {tabs.results.map((tab) => (
              <Tabs.Tab active={activeTab === tab.display_name} onClick={() => setNewTab(tab.display_name)}>
                {tab.display_name}
              </Tabs.Tab>
          ))}
        </Tabs>
        <Tabs type="toggle" align="centered">
          <Tabs.Tab
            active={activeView === "yesterday"}
            onClick={() => setNewView("yesterday")}
          >
            Yesterday
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "today"}
            onClick={() => setNewView("today")}
          >
            Today
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "started"}
            onClick={() => setNewView("started")}
          >
            Started
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "upcoming"}
            onClick={() => setNewView("upcoming")}
          >
            Upcoming
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "returned"}
            onClick={() => setNewView("returned")}
          >
            Returned From Workshop
          </Tabs.Tab>
          <Tabs.Tab
              active={activeView === "wash_needed"}
              onClick={() => setNewView("wash_needed")}
          >
            Wash Needed
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "finished"}
            onClick={() => setNewView("finished")}
          >
            Finished
          </Tabs.Tab>
          <Tabs.Tab
            active={activeView === "all"}
            onClick={() => setNewView("all")}
          >
            All
          </Tabs.Tab>
        </Tabs>
        {(activeView === "wash_needed") &&
            <Columns.Column pull={"right"}>
              <Form.Label>Wash List Date</Form.Label>
              <Form.Field className="contact-section has-addons">
                <Form.Control>
                  <Form.Input
                    type="date"
                    name="day"
                    value={date}
                    onChange={e => (setDate(e.target.value))}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
        }
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr className="small-row-black">
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
              {(activeView === 'finished' || activeView === 'all') &&
                <th>Approver</th>
              }
              {activeView === "wash_needed" &&
                <td onClick={e => e.stopPropagation()}>
                  <ReactToPrint
                    trigger={() => (
                      <Button color="warning">
                        Print
                      </Button>
                    )}
                    content={() => componentRef.current}
                    bodyClass="print-landscape"
                    pageStyle=""
                  />
                </td>
              }
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <JobRow
                key={index}
                job={value}
                onJobUpdate={onJobUpdate}
                index={index}
                endpoint={props.endpoint}
                showApprover={activeView === 'finished' || activeView === 'all'}
              />
            ))}
          </tbody>
        </table>
        <div style={{display: "none"}}>
          <div>
            {activeView === 'wash_needed' &&
              <PrintListWash data={data} ref={componentRef} testId="PrintWashList"/>
            }
          </div>
        </div>
        <Pagination
          showFirstLast={true}
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
    </div>
  );
}

export default Job;

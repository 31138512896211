export const getJobsData = state => {
  return state.data.jobs;
};

export const getQuotesData = state => {
  return state.data.quotes;
};

export const getDepartmentsData = state => {
  return state.data.departments;
}

export const getPartsData = state => {
  return state.data.parts;
};

export const getPartLookupData = state => {
  return state.data.partLookup;
};

export const getWorkLookupData = state => {
  return state.data.workLookup;
};

export const getRentalData = state => {
  return state.data.rentals;
};

export const getUserData = state => {
  return state.data.user;
};

export const getTransactionData = state => {
  return state.data.transactions;
};

export const getWeeklyTransactionData = state => {
  return state.data.weeklyTransactions;
};

export const getDataFresh = state => {
  return state.data.dataFresh;
};

export const getTransactionFiguresData = state => {
  return state.data.figures.transactionData;
};

export const getWorkFiguresData = state => {
  return state.data.figures.workFigures;
};

export const getPartFiguresData = state => {
  return state.data.figures.partFigures;
};

export const getRentalFiguresData = state => {
  return state.data.figures.rentalFigures;
};

export const getVehicleFiguresData = state => {
  return state.data.figures.vehicleFigures;
};

export const getTargetFiguresData = state => {
  return state.data.figures.target;
};

export const getTargetsData = state => {
  return state.data.targets;
};

export const getInspectionTypesData = state => {
  return state.data.inspectionTypes;
};

export const getInspectionData = state => {
  return state.data.inspections;
};

export const getVehicleTypesData = state => {
  return state.data.vehicleTypes;
};

export const getQuickLinksData = state => {
  return state.data.quickLinks;
};

export const getTransactionCategoriesData = state => {
  return state.data.transactionCategories;
};

export const getTransactionReportingCategoriesData = state => {
  return state.data.transactionReportingCategories;
};

export const getCostCentresData = state => {
  return state.data.costCentres;
};

export const getVehicleData = state => {
  return state.data.vehicle;
};

export const getFinanceData = state => {
  return state.data.finance;
};

export const getJobData = state => {
  return state.data.job;
};

export const getQuoteData = state => {
  return state.data.quote;
};

export const getDiaryData = state => {
  return state.data.diary;
};

export const getWorkData = state => {
  return state.data.work;
};

export const getVehiclesData = state => {
  return state.data.vehicles;
};

export const getVehicleSummaryData = state => {
  return state.data.vehicleSummary;
};

export const getAppDefaultsData = state => {
  return state.data.appDefaults;
};

export const getContactData = state => {
  return state.data.contact;
};

export const getContactsData = state => {
  return state.data.contacts;
};

export const getPaymentsData = state => {
  return state.data.payments;
};

export const getVehicleTrackersData = state => {
  return state.data.trackers;
}

export const getRecurringPaymentsData = state => {
  return state.data.recurringPayments;
}

import React from "react";
import { Table } from "react-bulma-components";
import { formatCurrency } from "../../utils";
import logo from "../Nav/img/Logo.jpg";
import { Columns } from "react-bulma-components";

class PrintQuote extends React.Component {
  PartRow(part) {
    return (
      <tr key={`p${part.id}`}>
        <td></td>
        <td></td>
        <td></td>
        <td>{part.name}</td>
        <td>{formatCurrency(part.estimated_customer_cost)}</td>
      </tr>
    );
  }

  WorkRow(work) {
    let rows = [];
    rows.push(
      <tr key={work.id} className="table-print-highlight">
        <td>{work.description}</td>
        <td>{work.estimated_labour_hours}</td>
        <td>{formatCurrency(work.estimated_customer_labour_cost)}</td>
        <td></td>
        <td></td>
      </tr>
    );
    rows = rows.concat(work.parts.map(part => this.PartRow(part)));
    return rows;
  }

  render() {
    const job = this.props.data;
    const estimated_work_items = job.estimated_work_items.filter(
      item => !item.added_after_quote
    );


    let labourTotal = 0;
    let partsTotal = 0;

    for (let i = 0; i < estimated_work_items.length; i++) {
      if (estimated_work_items[i].chargeable === this.props.chargeable) {
        labourTotal +=
          1 * estimated_work_items[i].estimated_customer_labour_cost;
        for (let j = 0; j < estimated_work_items[i].parts.length; j++) {
          partsTotal +=
            1 * estimated_work_items[i].parts[j].estimated_customer_cost;
        }
      }
    }

    return (
      <div className="page">
        <div>
          <Columns>
            <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd" />
            <h1 className="job-card-print-title">
              Quote - {job.vehicle.registration}
            </h1>
          </Columns>
          <Table bordered size={"fullwidth"}>
            <thead>
              <tr className="header-row">
                <td>Registration</td>
                <td>Make</td>
                <td>Model</td>
                <td>Customer</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{job.vehicle.registration}</td>
                <td>{job.vehicle.make}</td>
                <td>{job.vehicle.model}</td>
                <td>
                  {this.props.chargeable
                    ? job.customer2
                      ? job.customer2.display_name
                      : ""
                    : job.customer
                    ? job.customer.display_name
                    : ""}
                </td>
              </tr>
            </tbody>
          </Table>
          <Table bordered size={"fullwidth"}>
            <thead>
              <tr className="header-row">
                <td>Description</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{job.job_description}</td>
              </tr>
            </tbody>
          </Table>
          <Table bordered size={"fullwidth"}>
            <thead>
              <tr className="header-row">
                <td>Total Labour Cost</td>
                <td>Total Parts Cost</td>
                <td>Total Cost</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formatCurrency(labourTotal)}</td>
                <td>{formatCurrency(partsTotal)}</td>
                <td>{formatCurrency(labourTotal + partsTotal)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div>
          {
            <Table bordered size={"fullwidth"}>
              <thead>
                <tr className="header-row">
                  <td>Work Description</td>
                  <td>Labour Hours</td>
                  <td>Labour Cost</td>
                  <td>Part Name</td>
                  <td>Part Cost</td>
                </tr>
              </thead>
              <tbody>
                {Object.entries(estimated_work_items).length > 0 &&
                  Object.entries(estimated_work_items)
                    .filter(
                      item => item[1].chargeable === this.props.chargeable
                    )
                    .sort((a, b) => a[0].description < b[0].description)
                    .map(entry => this.WorkRow(entry[1]))}
              </tbody>
            </Table>
          }
        </div>
        <p className="terms">All prices are plus VAT. Our standard terms and conditions apply. A copy of
          these can be found on our website.</p>
      </div>
    );
  }
}

export default PrintQuote;

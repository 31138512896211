import React from "react";
import { Navbar } from "react-bulma-components";

function QuickLinksNav({quickLinks}) {
  return <Navbar.Dropdown>
    {quickLinks.map((quickLink, index) => <Navbar.Item key={index} onClick={() => window.open(`${quickLink.url}`, '_blank')}>
      {quickLink.title}
    </Navbar.Item>)}
  </Navbar.Dropdown>
}

export default QuickLinksNav;
